import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getUnreadFlareUpCount, getUnreadRednessCount, getScheduledCallCount, getLeaveRequestCount, getPatientCount, updatePatientCount } from './notification.service';
import { ROLES } from 'lib/utils';

export const useGetUnreadFlareupCount = (params) => {
    return useQuery(['notification-flareups', params], () => getUnreadFlareUpCount(params), {
        refetchInterval: 60000,
        enabled: params.role === ROLES.NUTRITIONIST || params.role === ROLES.PSYCHOLOGIST || params.role === ROLES.UV_COACH
    });
}

export const useGetUnreadRednessCount = (params) => {
    return useQuery(['notification-redness', params], () => getUnreadRednessCount(params), {
        refetchInterval: 60000,
        enabled: params.role === ROLES.UV_COACH
    });
}

export const useGetScheduledCallCount = (params) => {
    return useQuery(['notification-scheduled-call', params], () => getScheduledCallCount(params), {
        refetchInterval: 60000,
        enabled: params.role !== ROLES.ADMIN && params.role !== ROLES.PSORIASIS_ADMIN
    });
}

export const useGetLeaveRequestCount = (params) => {
    return useQuery(['notification-leave-request-count', params], () => getLeaveRequestCount(params), {
        refetchInterval: 60000,
        enabled: params.role === ROLES.ADMIN
    });
}

export const useGetPatientsCount = (params) => {
    return useQuery(['notification-patients-count', params.type], () => getPatientCount(params), {
        refetchInterval: 60000,
        enabled: params.role === ROLES.ADMIN || params.role === ROLES.CRM
    });
}

export const useUpdatePatientCount = (details) => {
  const queryClient = useQueryClient();
  const updatePatient = useMutation({
    mutationFn: ({ type, patientId, status }) =>
      updatePatientCount({ type, patientId, status }),
    onSuccess: async (_data, variables) => {
      queryClient.invalidateQueries([
        "notification-patients-count",
        variables.type,
      ]);
    },
  });
  return { updatePatient };
};